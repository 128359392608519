// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails

import axios from 'axios';
import IMask from 'imask';

document.addEventListener('DOMContentLoaded', setupCityToggles);
document.addEventListener('DOMContentLoaded', setupMasks);

function setupCityToggles()
{
    const isPriorityToggles = document.querySelectorAll('.city-is-priority-toggle');
    if (isPriorityToggles) {
        isPriorityToggles.forEach((isPriorityToggle) => {

            isPriorityToggle.addEventListener('input', function (e) {

                const cityUrl = e.target.dataset.updateCityUrl;
                const isPriority = e.target.checked;
                const authencityToken = document.querySelector('input[name="authenticity_token"]').value;

                const data = {
                    city: {
                        is_priority: isPriority,
                    },
                };

                const config = {
                    headers: {
                      'X-CSRF-Token': authencityToken,
                    },
                  };                
                
                axios
                    .patch(cityUrl, data, config)
                    .then((response) => {
                        console.log('Response:', response.data);
                    })
                    .catch((error) => {
                        e.target.value = !e.target.value;
                        console.error('Error:', error);
                    });
            });
        });
    }
}

function setupMasks()
{
    const doctorZip = document.querySelector('#doctor_cep');
    if (doctorZip) {
        IMask(
            doctorZip,
            {
              mask: '00000-000',
            },
          );
    }

    const doctorCpf = document.querySelector('#doctor_cpf');
    if (doctorCpf) {
        IMask(
            doctorCpf,
            {
              mask: '000.000.000-00',
            },
          );
    }
}